import {React, useContext, useRef, useState} from "react"
import {TextField} from "@material-ui/core";
import InputMask from "react-input-mask/lib/react-input-mask.development";
import {useSendForm} from "../hooks/send-from.hook";
import {BlocksContext} from "../context/blocksContext";

export const Plane_of_month = () => {
    const blocks = useContext(BlocksContext)
    const [state, setState] = useState(false);
    const sendForm = useSendForm()
    const [loaded, setLoaded] = useState(false)

    const showPopup = (e) => {
        e.preventDefault()
        console.log(blocks.blocks)
        if (blocks.blocks < 4) {
            blocks.setBlocks(24)
            let target = e.currentTarget
            setTimeout(() => {
                document.querySelector('body').classList.add('overflow')
                document.querySelector('.popup_rgba').style.display = "block"
                document.querySelectorAll('.popup_main').forEach(el => {
                    el.style.display = "none"
                });
                document.querySelector('.' + target.getAttribute('data')).style.display = "block"
            }, 1800)
        } else {
            document.querySelector('body').classList.add('overflow')
            document.querySelector('.popup_rgba').style.display = "block"
            document.querySelectorAll('.popup_main').forEach(el => {
                el.style.display = "none"
            });
            console.log(e.currentTarget.getAttribute('data'))
            document.querySelector('.' + e.currentTarget.getAttribute('data')).style.display = "block"
        }
    }
    return(
        <section className="anim plane-of-month">
            <div className="cw_main">
                <div className="tm"><span>Планировки месяца</span>
                    <div className="dec1"><img src="/images/dec1.svg"/></div>
                    <div className="dec2"><img src="/images/dec2.svg"/></div>
                </div>

                <div className="plane-of-month_list">
                    <div className="plane-of-month_list_item">
                        <div className="title">Планировка месяца</div>
                        <div className="img">
                            <img src="/images/plan-of-month.png" alt="планировка"/>
                            <div className="subtitle">Общая площадь: 60,3 м²</div>
                        </div>
                        <div className="button" onClick={showPopup} data="pu_fs_plan">Узнать подробнее</div>
                    </div>
                </div>
            </div>

            <div className={!state ? 'pu_action' : 'pu_action pu_action__open'}>
                <div className='pu_action__btn' onClick={() => {
                    setState(!state)
                }}></div>
                <div className='pu_action__img'><img src={loaded ? "/images/pu_action_img.jpg" : ""}/></div>
                <form className="pu_form_action">
                    <div className='pu_action__title'>Узнайте подробнее об условиях акции</div>
                    <div className='pu_form__in'>
                        <div className="form_in react_input_style">
                            <TextField name="name" label="Ваше имя" className="pu_name"/>
                        </div>
                        <div className="form_in react_input_style">
                            <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null}>
                                <TextField name="phone" label="Ваш телефон" className="pu_phone"/>
                            </InputMask>
                        </div>
                    </div>
                    <input type="hidden" className="text" value="Узнать подробнее об условиях акции"/>
                    <div className='align_center'>
                        <button className="pu_btn" onClick={sendForm.sendForm} celtype="getAction">Узнать подробнее
                        </button>
                    </div>
                    <div style={{marginTop: 20, textAlign:'center'}}>
                        <a href="/policy.pdf" target='_blank' rel="noreferrer">Политика конфиденциальности</a>
                    </div>
                </form>
            </div>
        </section>
    )
}